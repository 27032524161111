import React, { useMemo } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { DataGrid } from '../../components/data-grid/data-grid';
import {
  CellClassParams,
  CellStyle,
  ColDef,
  GridOptions,
  ICellRendererParams,
  RowClickedEvent,
  ValueGetterParams
} from 'ag-grid-community';
import { SubHeader } from '../organizations/subheader';
import { ORGANIZATION_CONSTANTS } from '../organizations/organization-constants';
import { styles } from './configuration.styles';
import { useHistory } from 'react-router-dom';
import { getConfigValueForServiceLine } from './config-helpers';

interface ConfigurationsDataGridProps {
  configurations: CMxAPI.Configuration[];
  serviceLines?: CMx.ServiceLine[];
  pageSize?: number;
  orgCode?: string;
  tenantId: string; // NEW
}

/** A small React component for the Field (Keyname) cell */
const FieldKeynameCellRenderer: React.FC<ICellRendererParams> = params => {
  const rowData = params.data as CMxAPI.Configuration;
  return (
    <div
      style={{
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        padding: '4px 0'
      }}>
      <div style={{ fontWeight: 'bold' }}>{rowData.keyname}</div>
      {rowData.displayName && (
        <div style={{ fontSize: '0.85em', color: 'gray' }}>
          {rowData.displayName}
        </div>
      )}
    </div>
  );
};

const ConfigurationsTable: React.FC<ConfigurationsDataGridProps> = ({
  configurations,
  serviceLines,
  pageSize = 100,
  orgCode,
  tenantId // NEW
}) => {
  const classes = styles();
  const history = useHistory();

  const handleRowClicked = (event: RowClickedEvent) => {
    const config = event.data as CMxAPI.Configuration;
    history.push(`/cmx/admin/orgs/${orgCode}/configurations/${config.id}`);
  };

  const colDefs: ColDef[] = useMemo(() => {
    const columns: ColDef[] = [
      {
        headerName: 'Field (Keyname)',
        field: 'keyname',
        cellRendererFramework: FieldKeynameCellRenderer,
        flex: 1,
        minWidth: 150,
        autoHeight: true,
        wrapText: true,
        sort: 'asc'
      }
    ];

    if (serviceLines) {
      serviceLines.forEach(sl => {
        columns.push({
          headerName: sl.name,
          valueGetter: (params: ValueGetterParams) => {
            const rowData = params.data as CMxAPI.Configuration;
            const cellVal = getConfigValueForServiceLine(
              rowData.values,
              sl.name,
              tenantId
            );
            return cellVal || 'null';
          },
          flex: 1,
          minWidth: 100,
          wrapText: true,
          autoHeight: true,
          cellStyle: (params: CellClassParams): CellStyle => {
            const style: CellStyle = {
              'white-space': 'normal',
              'word-break': 'break-word',
              color: 'inherit'
            };
            if (params.value === 'null') {
              style.color = 'lightgrey';
            }
            return style;
          }
        });
      });
    }

    return columns;
  }, [serviceLines, tenantId]);

  const gridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    onRowClicked: handleRowClicked
  };

  return (
    <Grid display="flex" flexDirection="column" rowGap={5}>
      <GridItem>
        <SubHeader
          className={classes.text}
          title={ORGANIZATION_CONSTANTS.CONFIGURATIONS}
        />
      </GridItem>
      <DataGrid
        rowData={configurations}
        columnDefs={colDefs}
        gridOptions={gridOptions}
        paginationPageSize={pageSize}
      />
    </Grid>
  );
};

export default ConfigurationsTable;
