import React, { useMemo } from 'react';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text
} from '@chakra-ui/react';
import { DataGrid } from '../../components/data-grid/data-grid';
import { getConfigValueForServiceLine } from './config-helpers';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';

interface CaseConfigDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  configDetail: CMxAPI.Configuration | null;
  serviceLines: CMx.ServiceLine[];
  tenantId: string;
}

const ConfigurationDetailDrawer: React.FC<CaseConfigDrawerProps> = ({
  isOpen,
  onClose,
  configDetail,
  serviceLines,
  tenantId
}) => {
  const rowData = useMemo(() => {
    if (!configDetail) return [];

    return serviceLines.map(sl => {
      const value = getConfigValueForServiceLine(
        configDetail.values,
        sl.name,
        tenantId
      );
      const match = configDetail.values.find(
        v => v.serviceLines?.includes(sl.name) || v.serviceLines?.includes('*')
      );
      const wildcard = configDetail.values.find(v =>
        v.serviceLines?.includes('*')
      );

      return {
        serviceLine: sl.name,
        value,
        updatedBy: 'System', // update this if author info becomes available
        updatedDate: match?.updatedDate
          ? new Date(Number(match.updatedDate)).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          : 'Unknown',
        defaultValue: wildcard?.value ?? null
      };
    });
  }, [configDetail, serviceLines, tenantId]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Service Line',
        field: 'serviceLine',
        flex: 1,
        autoHeight: true,
        wrapText: true,
        cellRenderer: (params: ICellRendererParams) => (
          <strong>{params.value}</strong>
        )
      },
      {
        headerName: 'Value',
        field: 'value',
        flex: 2,
        autoHeight: true,
        wrapText: true,
        cellRenderer: (params: ICellRendererParams) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '0.85em',
              padding: '10px 0',
              whiteSpace: 'normal',
              wordBreak: 'break-word'
            }}>
            <div>Value: {params.value}</div>
            {params.data.defaultValue && (
              <div style={{ color: 'gray' }}>
                Default value is typically: {params.data.defaultValue}
              </div>
            )}
            <div style={{ color: 'gray' }}>
              Last updated by {params.data.updatedBy} on{' '}
              {params.data.updatedDate}
            </div>
          </div>
        )
      }
    ],
    []
  );

  const gridOptions: GridOptions = useMemo(
    () => ({
      rowHeight: 80,
      suppressCellFocus: true,
      pagination: false,
      paginationPageSize: undefined
    }),
    []
  );

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size="md"
      onClose={onClose}
      blockScrollOnMount={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader pr="5rem">
          {configDetail?.displayName ||
            configDetail?.keyname ||
            'Configuration'}
        </DrawerHeader>
        <DrawerBody>
          {configDetail ? (
            <>
              <Box maxH="50%" overflowY="auto" mb={6}>
                <DataGrid
                  rowData={rowData}
                  columnDefs={columnDefs}
                  gridOptions={gridOptions}
                />
              </Box>

              <Divider mb={10} />

              <Box>
                <Text fontSize="sm" fontWeight="bold" mb={1}>
                  DESCRIPTION
                </Text>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  {configDetail.keyname}
                </Text>
                <Text>
                  {configDetail.description || 'No description provided.'}
                </Text>
              </Box>
            </>
          ) : (
            <Text>Loading configuration details...</Text>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ConfigurationDetailDrawer;
