/// <reference path="../types.d.ts" />
import { cmxReducerFactory } from './reducer-utils';
import InitialState from './initial-state';
import {
  fetchConfigDetail,
  listConfigurations,
  toggleConfigDrawer,
  toggleConfigDrawerContent
} from '../actions/configurations';
import { inProgress } from '../actions/common';

export const configurationReducer = cmxReducerFactory(
  InitialState.configurations
)
  // You can update state on inProgress if needed.
  .case(inProgress, state => ({
    ...state
    // Optionally, set a loading flag here
  }))
  // Handle fetching the full list of configurations
  .case(listConfigurations.async.done, (state, { result }) => ({
    ...state,
    configurations: result
  }))
  .case(listConfigurations.async.failed, (state, { error }) => ({
    ...state
    // Optionally, store error information here
  }))
  // Handle fetching a single configuration's detail for the drawer
  .case(fetchConfigDetail.async.done, (state, { result }) => ({
    ...state,
    configurationDetail: result
  }))
  .case(fetchConfigDetail.async.failed, (state, { error }) => ({
    ...state,
    configDetailError: error
  }))
  .case(toggleConfigDrawer, (state, isOpen: boolean) => ({
    ...state,
    isDrawerOpen: isOpen
  }))
  .case(toggleConfigDrawerContent, (state, isOpen: boolean) => ({
    ...state,
    isDrawerContentOpen: isOpen
  }));

export default configurationReducer;
