import React, { useEffect } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchConfigDetail,
  listConfigurations,
  toggleConfigDrawer,
  toggleConfigDrawerContent
} from '../../actions/configurations';
import { ThunkDispatch } from 'redux-thunk';
import ConfigurationsTable from './ConfigurationsTable';
import { AnyAction } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfigurationDetailDrawer from './configuration-detail-drawer';

const ConfigurationManagement = (props: any) => {
  const tenantId = props.tenantId || 'defaultTenantId';
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const history = useHistory();
  const { configId, orgId } = useParams<{
    configId?: string;
    orgId?: string;
  }>();

  const configurations: CMxAPI.Configuration[] = useSelector(
    (state: CMx.ApplicationState) => state.configurations.configurations
  );
  const configDetail: CMxAPI.Configuration[] | undefined = useSelector(
    (state: CMx.ApplicationState) => state.configurations.configurationDetail
  );
  const activeContext = useSelector(
    (state: CMx.ApplicationState) => state.ui.context.activeContext
  );
  const { serviceLines } = (activeContext as unknown) as {
    serviceLines: CMx.ServiceLine[];
    organizationCode: number;
  };

  const isDrawerOpen = useSelector(
    (state: CMx.ApplicationState) => state.configurations.isDrawerOpen
  );

  // Fetch the list of configurations on mount
  useEffect(() => {
    dispatch(listConfigurations({ tenantId }));
  }, [dispatch, tenantId]);

  // If there's a configId in the route, fetch its detail and open the drawer
  useEffect(() => {
    if (configId) {
      dispatch(fetchConfigDetail({ configurationId: configId, tenantId }));
      dispatch(toggleConfigDrawer(true));
      dispatch(toggleConfigDrawerContent(true));
    }
  }, [configId, tenantId, dispatch]);

  // Close the drawer and navigate back to the configurations list
  const handleDrawerClose = () => {
    dispatch(toggleConfigDrawer(false));
    dispatch(toggleConfigDrawerContent(false));
    history.push(`/cmx/admin/orgs/${orgId}/configurations`);
  };

  return (
    <Box>
      <Grid>
        <Flex>
          <BreadCrumbsComponent />
        </Flex>
      </Grid>
      <Grid>
        <Box>
          <ConfigurationsTable
            configurations={configurations}
            serviceLines={serviceLines}
            orgCode={orgId}
            tenantId={tenantId}
          />
        </Box>
      </Grid>
      {configId && (
        <ConfigurationDetailDrawer
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          configDetail={
            configDetail && configDetail.length ? configDetail[0] : null
          }
          serviceLines={serviceLines}
          tenantId={tenantId}
        />
      )}
    </Box>
  );
};

export default ConfigurationManagement;
