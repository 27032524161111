export function getConfigValueForServiceLine(
  values: CMxAPI.ConfigurationValue[],
  serviceLineName: string,
  tenantId: string
): string {
  const serviceLineAliases: Record<string, string> = {
    'Bedside Pro': 'Inpatient Professional Coding'
  };

  const normalizedServiceLine =
    serviceLineAliases[serviceLineName] || serviceLineName;

  // 1. Exact match on tenantId AND serviceLine
  const exactTenantAndService = values.find(
    v =>
      v.tenantId === tenantId && v.serviceLines?.includes(normalizedServiceLine)
  );
  if (exactTenantAndService) return exactTenantAndService.value;

  // 2. Exact tenantId with wildcard serviceLine
  const exactTenantWildcardService = values.find(
    v => v.tenantId === tenantId && v.serviceLines?.includes('*')
  );
  if (exactTenantWildcardService) return exactTenantWildcardService.value;

  // 3. Wildcard tenantId with exact serviceLine
  const wildcardTenantExactService = values.find(
    v => v.tenantId === '*' && v.serviceLines?.includes(normalizedServiceLine)
  );
  if (wildcardTenantExactService) return wildcardTenantExactService.value;

  // 4. Wildcard tenantId and wildcard serviceLine
  const fallbackWildcard = values.find(
    v => v.tenantId === '*' && v.serviceLines?.includes('*')
  );
  if (fallbackWildcard) return fallbackWildcard.value;

  return '';
}
