/// <reference path="../../types.d.ts" />
import classnames from 'classnames';
import React from 'react';
import { Route } from 'react-router-dom';
import { Card, UniversalFeedback } from '@codametrix/ui-components';
import { CMxComponents } from '@codametrix/ui-components/dist/types';

import { GitTag } from '../../core/git-info';

import { AdminTemplate } from '../../components/admin-template/admin-template';
import Keypress from '../../views/keypress/keypress-component';
import ContextChoiceForm from '../../views/context/context-choice-form';
import TableauDashboard from '../../views/analytics/tableau-dashboard-component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';
import TopBar from '../../components/topbar/topBar';
import { default as CaseListAndDetail } from '../../views/case/case-list/case-list-and-detail-component';

// For monitoring changes to the SPA's URL so we can rename views for DataDog
import { handleLocationChange } from '../../core/middleware/analytics-middleware';
import LocationEffect from '../../core/effects/location/LocationEffect';

import './cmx.scss';
import '../../styles/cmx.scss';
import '../../styles/AgGridStyles.css';

import checkBox from '../../assets/images/checkbox.svg';
import { StubContext } from '../../tests/fixtures/stubs/contexts';
import { USER_STUB } from '../../tests/fixtures/stubs/user';
import { setIsActive } from '../../core/net';
import {
  authenticationChannel,
  cmxLocation,
  commonActions,
  Utils
} from '@codametrix/ui-common';
import { Eula } from '../../views/eula/eula';
import ServiceLineMessage from '../../views/service-lines/service-line-message';
import { PrimaryGlobalNavigation } from '../../components/primary-global-navigation/primary-global-navigation';
import { Toast } from '../../components/toast-message/toast';
import { LogoutModal } from '../../components/logout-modal/logout-modal';
import { AccountSettings } from '../../views/account-settings/account-settings';
import { ProviderDirectory } from '../../views/provider-directory/provider-directory';
import LoadingOverlay from '../../components/loader-overlay/loader-overlay';
import { LicenseManager } from 'ag-grid-enterprise';
import { isNewDesign } from '../../core/middleware/new-design-middleware';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
import { CaseCancellationComponent } from '../components/case-cancellation-component';
import { InvoiceEditorComponent } from '../components/invoice-editor-component';
import { QueueSettings } from '../service-desk/service-desk-component';
import { PatientTimeline } from '../../views/patient-timeline/patient-timeline';
import { CMXDrawer } from '../../views/cmx-drawer/cmx-drawer';
import { ConnectedServiceDeskAndCodeBench } from '../service-desk/service-desk-and-codebench.component';
import { QualityAssessment } from '../service-desk/service-desk-component';
import { MergeCasesModal } from '../../components/merge-cases-modal/merge-cases-modal';
import { CreateCase } from '../../views/create-case/create-case';
import { AttachDocsModal } from '../../components/attach-docs-modal/attach-docs-modal';
import { ProviderDetails } from '../../views/provider-details/provider-details';
import { CapturedTime } from '../../views/time-entry/captured-time';
import { ConnectedServiceDeskAndGradeBench } from '../service-desk/service-desk-and-gradebench.component';
import {
  CQA_QUALITY_ASSESSMENT_GRADING_SCREEN,
  routedToGradingScreen
} from '../../core/utils/isCQAGradingScreen';
import { CoderNotes } from '../../views/coder-notes/coder-notes';

const SupportedResources = new Set<string>([
  `power_features`,
  `case_configuration`,
  `users`,
  'dictionary'
]);

class CMx extends React.Component<AppProps.CMx> {
  static defaultProps = {
    actionGroups: [],
    roles: [],
    hasResources: false,
    context: StubContext,
    contexts: [],
    activeUser: USER_STUB,
    caseLoadState: {
      loading: false,
      error: false,
      initial: false
    },
    awaitingTokenTransfer: false,
    phiMask: {
      active: false,
      available: false
    },
    dismissFeedback: (message: commonActions.CMxApplication.FEEDBACK) => {},
    onSelection: (selction: CMxAPI.NavAction) => {},
    onLoad: (navData: CMx.NavData) => {},
    selectAdminTab: (
      navPath: string,
      activeSubAction: CMxComponents.TabDef,
      navActionGroup: CMxComponents.TabDef[]
    ) => {}
  };
  registerActivity!: () => void;

  constructor(props: AppProps.CMx) {
    super(props);
    if (props?.config?.grid?.licenseKey) {
      LicenseManager.setLicenseKey(props.config.grid?.licenseKey);
    }
    this.handleDismissFeedback = this.handleDismissFeedback.bind(this);
    this.registerActivity = () => {
      setIsActive(true);
      /**
       * Transmitting child window activity on any of window to remaining all windows
       * to keep the session alive.       *
       */
      const message = {
        type: commonActions.AuthenticatedWindow.ACTIVITY,
        payload: true,
        meta: {
          suppressRedux: true
        }
      };
      authenticationChannel.postMessage(message);
    };
    this.handleAdminTabSelect = this.handleAdminTabSelect.bind(this);
  }

  componentDidMount() {
    this.props.onLoad({
      context: this.props.context,
      homepagePreference: this.props.homepagePreference
    });
    // TODO: remove this once we have resources for provider directory
    if (!isNewDesign) {
      const providerDirectoryTab = document.getElementById(
        'code_case'
      ) as HTMLElement;
      if (providerDirectoryTab) providerDirectoryTab.style.display = 'none';
    }

    window.addEventListener('mousedown', this.registerActivity, false);
    window.addEventListener('keypress', this.registerActivity, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.registerActivity, false);
    window.removeEventListener('keypress', this.registerActivity, false);
  }

  // TODO: remove this once we have resources for provider directory
  componentDidUpdate() {
    if (!isNewDesign) {
      const providerDirectoryTab = document.getElementById(
        'code_case'
      ) as HTMLElement;
      if (providerDirectoryTab) providerDirectoryTab.style.display = 'none';
    }
  }

  handleDismissFeedback() {
    //@ts-ignore
    this.props.dismissFeedback(this.props.feedback);
  }

  handleAdminTabSelect(item: CMxComponents.TabDef) {
    const { AdminTabs, selectAdminTab } = this.props;

    let tabs: CMxComponents.TabDef[] = AdminTabs.map(
      (subAction: CMxComponents.TabDef, index: number) => {
        const active = subAction.id === item.id;
        return { ...subAction, active: active };
      }
    );

    selectAdminTab(item.path ? item.path : '', item, tabs);
  }

  render() {
    const {
      awaitingTokenTransfer,
      roles,
      loading,
      loadingState,
      feedback,
      actionGroups,
      hasResources,
      onSelection,
      contexts,
      context,
      handleOrganizationChoice,
      handleServiceLineChoice,
      modal,
      phiMask,
      activeUser,
      AdminTabs,
      serviceLine,
      openAccountSettings,
      openCapturedTime,
      listStatus,
      updateStatus,
      listCapturedTime,
      listProtectedTime,
      deleteProtectedTimeEntry,
      updateProtectedTimeEntry,
      addProtectedTimeEntry,
      getMaxAllowedBackdate,
      getSubNavPaths,
      accountSettingsIsOpen,
      capturedTimeIsOpen,
      caseLoadState,
      showDashboardOptions
    } = this.props;

    const activeMask = phiMask.available && phiMask.active;
    const styleClasses = [
      isNewDesign ? `cmx-application-chakra` : `cmx-application`,
      `layout-full`,
      `min-dimensions`,
      `max-dimensions`,
      `full-height`,
      { 'mask-sensitive-data': activeMask },
      ...roles.map(role => `${role.roleName}-role`)
    ];

    const isNewFlow = Utils.doesPathEndWithV2();
    const atGradingScreen = routedToGradingScreen(window.location.pathname);

    let mainStyle;
    if (isNewDesign) {
      if (isNewFlow || atGradingScreen) {
        if (this.props.showSideNavBar) {
          mainStyle = { top: 0, marginLeft: theme.space[96] };
        } else {
          mainStyle = { top: 0, marginLeft: 0 };
        }
      } else {
        mainStyle = { top: 0, marginLeft: theme.space[96] };
      }
    } else {
      mainStyle = { top: '40px' };
    }

    const noActiveContext =
      !loading && (!context || context.activeContext === null);
    const noEulaAcceptance = cmxLocation.getLocation() === '/eula/';
    const noRoutes = noActiveContext || noEulaAcceptance;
    if ((loading || loadingState?.isLoading) && !caseLoadState.loading) {
      if (isNewDesign) {
        styleClasses.push(`cmx-application-chakra-masked`);
      } else {
        styleClasses.push(`cmx-application-masked cmx-application-loading`);
      }
    } else if (noActiveContext) {
      styleClasses.push(
        `cmx-application-masked cmx-application-choose-context`
      );
    } else {
      styleClasses.push(`cmx-application-loaded`);
    }
    const resources = context?.activeContext?.resources ?? [];
    resources.forEach(resource => {
      if (SupportedResources.has(resource.resourceName)) {
        styleClasses.push(`${resource.resourceName}-active`);
      }
    });

    const logout = () => {
      const orgCode = context.activeContext?.organizationCode ?? '';
      this.props.logout(orgCode);
    };

    const activeOrgDisplay =
      context.activeContext?.shortDisplayName &&
      context.activeContext?.shortDisplayName !== ''
        ? context.activeContext?.shortDisplayName
        : context.activeContext?.displayName &&
          context.activeContext?.displayName !== ''
        ? context.activeContext?.displayName
        : context.activeContext?.organizationCode &&
          context.activeContext?.organizationCode !== ''
        ? context.activeContext?.organizationCode
        : '';

    const appContents = awaitingTokenTransfer ? (
      false
    ) : (
      <Keypress>
        <div id="appRoot" className={classnames(styleClasses)}>
          {loadingState?.isLoading && (
            <div className="centered-application">
              <div className="centered-application-wrapper">
                <LoadingOverlay loadingMsg={loadingState?.loadingMsg} />
              </div>
            </div>
          )}
          {!modal && !isNewDesign && (
            <div
              className="centered-application loading-view"
              data-testid="loading-view">
              <div className="centered-application-wrapper">
                <LoadingIndicator />
              </div>
            </div>
          )}{' '}
          {noActiveContext && (
            <div className="centered-application choice-view">
              <div className="centered-application-wrapper">
                <ContextChoiceForm
                  context={context && context.activeContext}
                  handleOrganizationChoice={handleOrganizationChoice}
                  contexts={contexts}
                  user={activeUser}
                />
              </div>
            </div>
          )}
          {noEulaAcceptance && !noActiveContext && <Eula />}
          {feedback && !isNewDesign && (
            <UniversalFeedback
              dismissFeedback={this.handleDismissFeedback}
              feedback={feedback}
              showFeedback={feedback ? true : false}
            />
          )}
          {feedback && isNewDesign && (
            <Toast feedback={feedback} status={feedback.meta?.level} />
          )}
          <span>{modal && <LogoutModal {...modal} />}</span>
          <span>{accountSettingsIsOpen && !modal && <AccountSettings />}</span>
          <span>
            {capturedTimeIsOpen && !modal && (
              <CapturedTime
                listCapturedTime={listCapturedTime}
                listProtectedTime={listProtectedTime}
                deleteProtectedTimeEntry={deleteProtectedTimeEntry}
                updateProtectedTimeEntry={updateProtectedTimeEntry}
                addProtectedTimeEntry={addProtectedTimeEntry}
                getMaxAllowedBackdate={getMaxAllowedBackdate}
                listStatus={listStatus}
                user={activeUser}
                context={context}
              />
            )}
          </span>
          {isNewDesign ? (
            isNewFlow || atGradingScreen ? (
              this.props.showSideNavBar ? (
                <nav className="layout-navbar">
                  <PrimaryGlobalNavigation
                    actionGroups={actionGroups}
                    activeOrg={activeOrgDisplay}
                    activeUser={activeUser}
                    onSelection={onSelection}
                    handleOrganizationChoice={handleOrganizationChoice}
                    handleServiceLineChoice={handleServiceLineChoice}
                    context={context}
                    onLogout={logout}
                    serviceLine={serviceLine}
                    openAccountSettings={openAccountSettings}
                    openCapturedTime={openCapturedTime}
                    listStatus={listStatus}
                    updateStatus={updateStatus}
                    showDashboardOptions={showDashboardOptions}
                    getSubNavPaths={getSubNavPaths}
                  />
                </nav>
              ) : null
            ) : (
              <nav className="layout-navbar">
                <PrimaryGlobalNavigation
                  actionGroups={actionGroups}
                  activeOrg={activeOrgDisplay}
                  activeUser={activeUser}
                  onSelection={onSelection}
                  handleOrganizationChoice={handleOrganizationChoice}
                  handleServiceLineChoice={handleServiceLineChoice}
                  context={context}
                  onLogout={logout}
                  serviceLine={serviceLine}
                  openAccountSettings={openAccountSettings}
                  openCapturedTime={openCapturedTime}
                  listStatus={listStatus}
                  updateStatus={updateStatus}
                  showDashboardOptions={showDashboardOptions}
                  getSubNavPaths={getSubNavPaths}
                />
              </nav>
            )
          ) : (
            <nav className="layout-topbar topbar-container qa-topbar-container">
              <TopBar
                handleOrganizationChoice={handleOrganizationChoice}
                handleServiceLineChoice={handleServiceLineChoice}
                context={context}
                actionGroups={actionGroups}
                onSelection={onSelection}
                onLogout={logout}
                activeOrg={context.activeContext?.displayName || ''}
                activeUser={activeUser}
                serviceLine={serviceLine}
                openAccountSettings={openAccountSettings}
              />
            </nav>
          )}
          <div className="layout-main" style={mainStyle}>
            {!noRoutes && (
              <main
                className="content-container min-dimensions"
                style={isNewDesign ? { padding: 0 } : {}}>
                <CaseCancellationComponent />
                <InvoiceEditorComponent />
                <CMXDrawer />
                <PatientTimeline />
                <ProviderDetails />
                <CoderNotes />
                <CreateCase />
                <MergeCasesModal />
                <AttachDocsModal />

                <div
                  className={isNewFlow ? '' : 'layout-full layout-inner-body'}>
                  <Route
                    path="/cmx/cases/:serviceLine/:id?"
                    exact
                    component={CaseListAndDetail}
                  />
                  <Route
                    path="/cmx/cases-old/:serviceLine/:id?"
                    exact
                    component={CaseListAndDetail}
                  />
                  <Route
                    path={['/cmx/analytics/', '/cmx/cases/', '/cmx/cases-old/']}
                    exact
                    component={ServiceLineMessage}
                  />
                  <Route
                    path="/cmx/tableau/"
                    exact
                    component={TableauDashboard}
                  />
                  <Route
                    exact
                    path={[`${CQA_QUALITY_ASSESSMENT_GRADING_SCREEN}/:id/`]}
                    component={ConnectedServiceDeskAndGradeBench}></Route>
                  <Route
                    exact
                    path={[
                      '/cmx/worklist/:serviceLine/case/:caseId/case-run/:id/',
                      '/cmx/worklist/:serviceLine/case/:caseId/case-run/:id/v2/',
                      '/cmx/worklist'
                    ]}
                    component={ConnectedServiceDeskAndCodeBench}></Route>
                  <Route
                    path={[
                      '/cmx/coding-quality/:serviceLine/case/:caseId/case-run/:id/',
                      '/cmx/coding-quality/:serviceLine/case/:caseId/case-run/:id/v2/',
                      '/cmx/coding-quality'
                    ]}
                    exact
                    component={ConnectedServiceDeskAndCodeBench}
                  />
                  <Route
                    path="/cmx/quality-assessment"
                    exact
                    component={QualityAssessment}
                  />
                  <Route
                    path="/cmx/queue-settings"
                    component={QueueSettings}
                    exact></Route>
                  <div
                    style={isNewDesign ? { maxWidth: '100%' } : {}}
                    className="max-dimensions">
                    <Route path="/cmx/admin/">
                      <AdminTemplate
                        title="Admin"
                        items={AdminTabs}
                        onSelection={this.handleAdminTabSelect}
                      />
                    </Route>
                  </div>
                  <Route
                    path="/cmx/provider_directory/"
                    exact
                    component={ProviderDirectory}
                  />
                  <Route path="/cmx/" exact>
                    {!hasResources && (
                      <Card
                        className="content-vacant"
                        data-testid="content-vacant">
                        <img
                          alt="checkBox"
                          src={checkBox}
                          className="check-box"
                        />
                        <h2>
                          <b>Successful authentication</b>
                        </h2>
                        <h4>
                          Please contact CodaMetrix if you require assistance
                          setting up an HL7 interface.
                        </h4>
                        <p> support@codametrix.com </p>
                      </Card>
                    )}
                  </Route>
                </div>
              </main>
            )}
          </div>
          <div className="layout-fullscreen-portal" id="fullscreenPortal"></div>
          <GitTag />
        </div>
        <LocationEffect onLocationChange={handleLocationChange} />
      </Keypress>
    );

    if (isNewDesign) {
      return <ChakraProvider theme={theme}>{appContents}</ChakraProvider>;
    }

    return appContents;
  }
}

export default CMx;
