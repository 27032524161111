import { HttpMethods } from '@codametrix/ui-common';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<any>(actionCreator);

interface ListConfigurationsPayload {
  tenantId: string;
}

export const listConfigurations = createAsync<
  ListConfigurationsPayload,
  any,
  any
>('LIST_CONFIGURATIONS', async (payload, dispatch) => {
  const { tenantId } = payload;
  return await api({
    endpoint: `/configuration/listAllConfigurationByTenantId/${tenantId}/v2`,
    init: {
      method: HttpMethods.GET
    }
  });
});

interface FetchConfigDetailPayload {
  configurationId: string | number;
  tenantId: string;
}

export const fetchConfigDetail = createAsync<
  FetchConfigDetailPayload,
  any,
  any
>('FETCH_CONFIG_DETAIL', async (payload, dispatch) => {
  const { configurationId, tenantId } = payload;
  return await api({
    endpoint: `/configuration/detail/${configurationId}/${tenantId}/v2`,
    init: {
      method: HttpMethods.GET
    }
  });
});

export const toggleConfigDrawer = actionCreator<boolean>(
  'TOGGLE_CONFIG_DRAWER'
);
export const toggleConfigDrawerContent = actionCreator<boolean>(
  'TOGGLE_CONFIG_DRAWER_CONTENT'
);
